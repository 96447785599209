import { fallbackRestUrl } from "./path";
export const verifyFile = async (fileKey: string, token: any) => {
  const response = await fetch(`${fallbackRestUrl}/file/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify({ file: fileKey }),
  });

  const data = await response.json();
  return data?.result?.success;
};

const FetchFileService = async (
  url: string,
  method = 'GET',
  variables: File | null = null,
  type: string | null = null
) => {
  const retries = 3;
  let attempt = 0;

  while (attempt <= retries) {
    try {
      const headers: Record<string, string> = {};

      if (type) headers['Content-Type'] = type;

      const response = await fetch(url, {
        headers,
        method,
        body: variables,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return { ok: true, data: response };
    } catch (err: any) {
      attempt++;
      console.warn(`Attempt ${attempt} failed: ${err.message || err}`);

      if (attempt > retries) {
        console.error('All retry attempts failed');

        throw new Error(
          JSON.stringify(err?.response?.data) || 'FetchFileService failed after all attempts'
        );
      }
    }
  }

  throw new Error('Unexpected error'); // Just in case the loop exits without returning or throwing
};

export default FetchFileService;

